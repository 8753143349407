@keyframes bounce {
    0% { transform: scale(1, 1) }
    50% { transform: scale(0.95, 0.95) }
    100% { transform: scale(1, 1) translateX(0px) }
}

.desktop_root{
    width: 100%;
    height: calc(var(--navbar_height));
    display: flex;
    flex-direction: row;
    z-index: 20;
    margin-top: 0px;
    top: 0;
    position: fixed;
}

@supports (backdrop-filter: blur(20px)) {
    .desktop_root{
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
    }
}

.desktop_root_on_top{
    width: 100%;
    height: calc(var(--navbar_height));
    display: flex;
    flex-direction: row;
    z-index: 20;
    margin-top: 0px;
    top: 0;
    position: fixed;
}

.desktop_inner{
    height: var(--navbar_height);
    width: 100%;
    max-width: var(--max_width);
    margin: 0 auto !important;
    float: none !important; 
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 19;
    //border: 1px solid red;
}

.logo{
    width: 175px;
    height: 50px;
    opacity: 1.0;
    will-change: opacity;
    transition: opacity 0.15s ease-in-out;
    //border: 1px solid green;
}

.logo:hover{
    cursor: pointer;
    opacity: 0.75;
    will-change: opacity;
    transition: opacity 0.15s ease-in-out;
}

@media only screen and (max-width: 1050px) {
    .logo{
        width: 125px;
        height: 50px;
        will-change: opacity;
    }
}

.desktop_sub_root{
    display: table-cell;
    width: 100%;
    height: var(--navbar_height);
    position: fixed;
    margin-top: -1.5px;
    top: 0;
    //background-color: var(--quinary_colour);
    z-index: 20;
    border-bottom: 1.5px solid var(--primary_colour);
    transition: background-color 0.15s ease;
}

.hamburguer_menu_icon_div{
    height: fit-content;
    width: fit-content;
    margin-left: 20px;
    //border: 1px solid red;
}

@media only screen and (min-width: 1050px) {
    .hamburguer_menu_icon_div{
        display: none;
    }
}

.hamburguer_menu_icon{
    width: 20px;
    height: 20px;
    opacity: 0.75;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: opacity 0.25s ease-in-out;
    //border: 1px solid red;
}

.hamburguer_menu_icon:hover{
    cursor: pointer;
    //opacity: 0.85;
    //transition: opacity 0.25s ease-in-out;
}

.hamburguer_menu_div_left{
    width: 235px;
    position: absolute;
    z-index: 90000;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-items: space-between;
    touch-action: none;
    -ms-touch-action: none;
    background-color: rgb(255, 255, 255);
    box-shadow: 10px 0 30px -2px rgba(0, 0, 0, 0.15);
    //border: 2px solid black;
}

.hamburguer_menu_blur_div{
    background-color: rgba(207, 207, 207, 0.5);
    height: 101vh;
    width: 100vw;
    position: absolute;
    z-index: 89999;
    touch-action: none;
    -ms-touch-action: none;
    transition: opacity 0.25s ease-in-out;
    /* Add the blur effect */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.hamburguer_menu_div_left_background{
    width: 100%;
    flex: 1;
    flex-basis: 100%;
    height: 75%;
    bottom: 0;
    position: absolute;
    opacity: 0.5;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(249, 249, 249,0)), to(rgba(249, 249, 249,1)));
    mask-image: linear-gradient(to bottom, rgba(249, 249, 249, 0), rgba(249, 249, 249, 1.0));
    //border: 1px solid red;
}

.hamburguer_menu_div_right{
    flex: 2;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}

.hamburguer_menu_div_left_inner{
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    //border: 1px solid red;
}

.home_link_div{
    align-self: flex-start;
    margin-top: 30px;
    height: fit-content;
    width: 100%;
    text-align: center;
    //border: 1px solid yellow;
}

.home_link{
    height: fit-content;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
    font-family: bold;
    font-size: 25px;
    opacity: 0.85;
    transition: opacity 0.25s ease-in-out;
    //color: var(--primary_colour);
    color: var(--primary_colour);
}

.home_link:hover{
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 0.25s ease-in-out;
}

.description{
    height: fit-content;
    width: fit-content;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    font-family: regular;
    font-size: 11px;
    opacity: 0.75;
    color: var(--primary_colour);
}

.hamburguer_menu_item_div{
    flex-basis: 100%;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;
    //border: 1px solid green;
}

.hamburguer_menu_item_div:hover{
    cursor: pointer;
    background-color: rgba(225, 225, 225, 0.35);
}

.hamburguer_menu_item_icon_div{
    flex: 1;
    width: fit-content;
    //border: 1px solid red;
}

.hamburguer_menu_item_icon{
    height: auto;
    width: 15px;
    aspect-ratio: 0.4;
    opacity: 0.75;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: opacity 0.25s ease-in-out;
    //border: 1px solid red;
}

.hamburguer_menu_item_text{
    flex: 5;
    font-size: 14px;
    opacity: 0.65;
    transition: opacity 0.25s ease-in-out;
}

.hamburguer_menu_item_div:hover .hamburguer_menu_item_text{
    opacity: 1.0;
    transition: opacity 0.25s ease-in-out;
}

.hamburguer_menu_bottom_div{
    height: 50%;
    width: 100%;
    position: relative;
    align-self: flex-end;
    //border: 1px solid green;
}

.hamburguer_menu_bottom_div_language_selector{
    height: fit-content;
    position: absolute;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    top: 20px;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    background-color: white;
    //border: 1px solid rgba(116, 2, 2, 0.25);
}

.middle_div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 25px;
    justify-content: flex-end;
    //border: 1px solid red;
}

@media only screen and (max-width: 1050px) {
    .middle_div{
        display: none;
    }
}

.middle_div_item{
    height: fit-content;
    width: fit-content;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 8px;
    font-family: regular;
    font-size: 15px;
    color: rgba(90, 90, 90, 1.0);
    opacity: 1.0;
    background-color: rgba(255, 255, 255, 0.0);
    border: 1px solid rgba(230, 230, 230, 0.0);
    transition: all 0.15s ease-in-out;
    //border: 1px solid red;
}

.middle_div_item:hover{
    //background-color: rgba(240, 240, 240, 0.15);
    //border: 1px solid rgb(162, 162, 162);
    //opacity: 0.65;
    cursor: pointer;
}

.vertical_align{
    vertical-align: middle;
    display: table-cell;
    height: 100%;
    z-index: -10;
    width: 75px;
}

.home_div{
    position: relative;
    height: 100%;
    width: fit-content;
    max-height: var(--navbar_height);
    text-align: center;
    margin-left: 20px;
    display: table;
    //border: 1px solid blue;
}

.button_div{
    position: relative;
    width: 200px;
    height: var(--navbar_height);
    text-align: center;
    display: flex;
    margin-left: auto;
    //border: 1px solid blue;
}

@media only screen and (max-width: 1050px) {
    .button_div{
        width: 175px;
    }
}

.button_inner_div{
    position: relative;
    width: fit-content;
    height: var(--navbar_height);
    text-align: center;
    display: table;
    margin-left: auto;
    margin-right: 20px;
}

.button_text{
    
    font-family: regular;
    font-size: 13.5px;
    color: white;
    //color: var(--primary_colour);
    transition: color 0.3s;
}

@media only screen and (max-width: 1150px) {
    .button_text{
        font-size: 13px;
    }
}

@media only screen and (max-width: 1050px) {
    .button_text{
        font-size: 12px;
    }
}

.button_button{

    box-sizing: border-box;
    border-radius: 20px;
    background: var(--primary_colour);
    //background: transparent;
    border: 2px solid var(--primary_colour);
    //border: 2px solid white;
    height: 40px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 17px;
    padding-bottom: 17px;
    white-space: nowrap;
    justify-content: center; // horizontal align
    display: flex; // vertical align
    align-items: center; // vertical align
    cursor: pointer;
    margin-left: 10px;
    transition: all ease 0.25s;
}

@media only screen and (max-width: 1050px) {
    .button_button{
        height: 35px;
        padding-left: 12px;
        padding-right: 12px;
    }
}

.button_button:hover{
    box-shadow: rgba(51, 89, 180, 0.75) 0px 2px 10px 0px;
    transition: all ease 0.25s;
}

.button_button:hover .button_text{
    color: white;
    //color: rgba(0, 0, 0, 1.0);
}

.button_button[show_bounce_animation='1'] {
    animation: bounce 0.25s 1;
}

.services_modal_div{
    position: fixed;
    z-index: 200;
    height: fit-content;
    width: fit-content;
    padding-top: 25px;
    will-change: opacity;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    //border: 1px solid red;
}