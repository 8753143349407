.root_div{
    position: relative;
    height: fit-content;
    width: 100%;
    margin: 0 auto;
    //border: 2px solid green;
}

.call_to_action_div{
    height: fit-content;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
}

.call_to_action_background_div{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-repeat: repeat;
    background-size: 200px;
    background-position: center;
    //border: 2px solid green;
}

.call_to_action_inner_div{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 auto;
    max-width: var(--max_width);
}

.call_to_action_text_div{
    text-align: center;
    font-family: bold;
    padding-left: 80px;
    padding-right: 80px;
    color: var(--secondary_colour);
}

.call_to_action_text_div bold{
    color: var(--primary_colour);
}

.five_pillars_div{
    position: relative;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;
    width: 100%;
    height: fit-content;
    //border: 1px solid red;
}

@media only screen and (max-width: 850px) {
    .five_pillars_div {
        padding-top: 60px;
        padding-bottom: 30px;
    }
}

.five_pillars_background_div{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 1.0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //border: 2px solid green;
}

.five_pillars_inner_div{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 40px;
    padding-left: 30px;
    padding-right: 30px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    margin: 0 auto;
    max-width: var(--max_width);
}

@media only screen and (max-width: 850px) {
    .five_pillars_inner_div {
        gap: 10px;
    }
}

.five_pillars_left_text_div{
    flex: 2;
    text-align: center;
    font-family: bold;
    color: rgba(115, 127, 151, 1.0);
}

@media only screen and (max-width: 850px) {
    .five_pillars_left_text_div {
        flex-basis: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.five_pillars_items_div{
    flex: 3;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.five_pillars_item_div{
    padding: 15px;
    border-radius: 8px;
    border: 1px solid rgba(202, 202, 202, 1.0);
    background: rgba(255, 255, 255, 0.20);
    align-items: flex-start;
}

@supports (backdrop-filter: blur(7px)) {
    .five_pillars_item_div{
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
    }
}

.five_pillars_item_title{
    flex-basis: 100%;
    font-family: bold;
    font-size: 14px;
    letter-spacing: 2px;
    color: var(--primary_colour);
}

.five_pillars_subitems_div{
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.five_pillars_subitem_text{
    flex: 0;
    width: fit-content;
    flex-basis: 100%;
    opacity: 0.55;
    font-family: regular;
    text-align: left;
    font-size: 15px;
    //border: 1px solid red;
}

.company_description_div{
    position: relative;
    height: fit-content;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 70px;
}

.company_description_background_div{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-repeat: repeat;
    background-size: 200px;
    background-position: center;
}

.company_description_inner_div{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: left;
    margin: 0 auto;
    max-width: var(--max_width);
}

.company_description_main_text{
    width: fit-content;
    text-align: center;
    font-family: bold;
    padding: 40px;
    margin: 0 auto;
    color: rgba(115, 127, 151, 1.0);
}

.company_description_main_text bold{
    color: var(--primary_colour);
}

.company_description_secondary_text{
    width: fit-content;
    text-align: center;
    font-family: regular;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgba(115, 127, 151, 1.0);
}

.company_description_secondary_text bold{
    font-family: semibold;
    color: var(--primary_colour);
}

.services_items_div{
    flex-basis: 100%;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.services_item_div{
    overflow: hidden;
    flex-basis: 25%;
    background-color: rgba(255, 255, 255, 1.0);
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: rgba(251, 253, 254, 1.0);
    border: 1px solid rgba(220, 220, 220, 1.0);
    box-shadow: rgba(51, 89, 180, 0.55) 0px 10px 20px -10px;
}

@media only screen and (max-width: 1000px) {
    .services_item_div {
        min-width: 350px;
    }
}

.services_item_image{
    border-radius: 8px 8px 0px 0px;
    height: 100px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.services_item_fade{
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(251, 253, 254, 0.0) 0%, rgba(251, 253, 254, 1.0) 100%);
    //border: 1px solid red;
}

.services_item_content_div{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    //border: 1px solid red;
}

.services_item_title{
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    font-family: semibold;
    font-size: 15px;
    text-align: center;
    color: var(--primary_colour);
}

.services_item_description{
    width: 80%;
    margin: 0 auto;
    font-family: light;
    word-wrap: break-word;
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
    color: var(--primary_colour);
}

.lets_talk_banner_div{
    height: fit-content;
    position: relative;
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: var(--primary_colour);
}

.lets_talk_banner_background_div{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    aspect-ratio: 1.2;
    opacity: 1.0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    //border: 2px solid green;
}

@media only screen and (max-width: 800px) {
    .lets_talk_banner_background_div {
        height: 75%;
    }
}

@media only screen and (max-width: 500px) {
    .lets_talk_banner_background_div {
        height: 50%;
    }
}

.lets_talk_banner_inner_div{
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: left;
    margin: 0 auto;
    max-width: var(--max_width);
}

.lets_talk_banner_text{
    flex-basis: 100%;
    padding-right: 25%;
    width: fit-content;
    text-align: left;
    font-family: bold;
    color: rgba(255, 255, 255, 0.65);
    //border: 1px solid red;
}

@media only screen and (max-width: 800px) {
    .lets_talk_banner_text {
        padding-right: 50%;
    }
}

.lets_talk_banner_text white{
    color: rgba(255, 255, 255, 1.0);
}

.lets_talk_button{
    height: fit-content;
    width: fit-content;
    border-radius: 200px;
    background-color: rgba(255, 255, 255, 1.0);
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.lets_talk_button_text{
    font-family: regular;
    font-size: 15px;
    color: var(--primary_colour);
}

.lets_talk_button_arrow{
    margin-left: 0px;
    width: 15px;
    height: 10px;
    opacity: 1.0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: all 0.15s ease-in-out;
    //border: 1px solid red;
}

.lets_talk_button:hover .lets_talk_button_arrow{
    margin-left: 5px;
}