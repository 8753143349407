.root_div{
    position: relative;
    height: fit-content;
    width: fit-content;
    border-radius: 8px;
    padding: 12px;
    padding-left: 18px;
    padding-right: 18px;
    border: 1px solid rgba(150, 150, 150, 0.5);
    box-shadow: rgba(51, 89, 180, 0.55) 0px 10px 20px -10px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    //border: 2px solid green;
}

@supports (backdrop-filter: blur(20px)) {
    .root_div{
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
    }
}

.item_div{
    width: fit-content;
    font-family: regular;
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: rgba(57, 74, 106, 0.5);
    opacity: 0.75;
    transition: all 0.15s ease-in-out;
}

.item_div:hover{
    cursor: pointer;
    color: var(--primary_colour);
    opacity: 1.0;
    transition: all 0.15s ease-in-out;
}