.root_div{
    position: relative;
    height: fit-content;
    width: 100%;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 75px;
    //border: 2px solid green;
}

.root_div_background{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-repeat: repeat;
    background-size: 200px;
    background-position: center;
    //border: 2px solid green;
}

.root_inner_div{
    position: relative;
    max-width: var(--max_width);
    height: fit-content;
    margin: 0 auto;
}

.suptitle_div{
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--secondary_colour);
    font-family: light;
    font-size: 18px;
}

.title_div{
    text-align: center;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--primary_colour);
    font-family: bold;
    font-size: 25px;
}

.items_div{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: fit-content;
    width: 93%;
    margin: 0 auto;
    margin-top: 40px;
    gap: 30px;
    justify-content: center;
    //border: 1px solid green;
}

@media only screen and (max-width: 750px) {
    .items_div {
        gap: 10px;
    }
}

.item_div{
    flex: 0;
    display: flex;
    width: fit-content;
    min-width: 250px;
    position: relative;
    overflow: visible;
    //border: 1px solid red;
}

@media only screen and (max-width: 750px) {
    .item_div {
        min-width: 180px;
    }
}

.item_inner_div{
    flex: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: none;
    transform: scale(1.0);
    image-rendering: optimizeQuality;
    transition: all 0.15s ease-in-out;
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(175, 175, 175, 0.5);
}

@supports (backdrop-filter: blur(5px)) {
    .first_part_div{
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
}

.item_inner_div:hover{
    box-shadow: rgba(51, 89, 180, 0.55) 0px 10px 20px -10px;
    border: 1px solid var(--secondary_colour);
    cursor: pointer;
    transform: scale(1.02);
    transition: all 0.25s ease-in-out;
}

@media only screen and (max-width: 750px) {
    .item_inner_div {
        transform: scale(1.0);
    }

    .item_inner_div:hover {
        transform: scale(1.0);
    }
}

.item_background{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
    width: 99%;
    height: 50%;
    top: 0;
    background-repeat: repeat;
    background-size: 200%;
    background-position: center;
    transform: rotate(180deg);
    opacity: .35;
    border-radius: 12px;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.item_separator{
    opacity: 0.0;
    position: relative;
    height: 90%;
    width: 1px;
    background-color: rgba(175, 175, 175, 0.25);
}

.image_div{
    position: relative;
    margin: 0 auto;
    height: 150px;
    width: 150px;
    border-radius: 200px;
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.15s ease-in-out;
    image-rendering: optimizeQuality;
    border: 2px solid var(--primary_colour)
}

@media only screen and (max-width: 750px) {
    .image_div {
        height: 100px;
        width: 100px;
        image-rendering: optimizeQuality;
    }
}

.item_inner_div:hover .image_div{
    transition: all 0.15s ease-in-out;
    image-rendering: optimizeQuality;
    box-shadow: rgba(51, 89, 180, 0.55) 0px 10px 20px -10px;
}

.linkedin_div{
    height: fit-content;
    width: fit-content;
    position: absolute;
    padding: 10px;
    top: 10px;
    right: 10px;
    background-color: var(--primary_colour);
    border-radius: 200px;
    transform: scale(0.9);
    image-rendering: optimizeQuality;
    will-change: transform;
    transition: transform 0.15s ease-in-out;
    //border: 1px solid green;
}

.linkedin_div:hover{
    cursor: pointer;
    transform: scale(1.0);
    transition: transform 0.15s ease-in-out;
}

.linkedin_image{
    height: 17px;
    width: 17px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.name_div{
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 20px;
    color: var(--primary_colour);
    font-family: semibold;
    font-size: 15px;
}

.role_div{
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
    color: var(--primary_colour);
    font-family: light;
    font-size: 15px;
}