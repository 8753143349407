@keyframes bounce {
    0% { transform: scale(1, 1) }
    50% { transform: scale(0.95, 0.95) }
    100% { transform: scale(1, 1) translateX(0px) }
}

.root_div{
    position: relative;
    height: fit-content;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(248, 248, 248, 1.0);
    //border: 2px solid green;
}

.root_div_top_bar{
    height: 4px;
    width: 100vw;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: var(--primary_colour);
}

.root_inner_div{
    position: relative;
    max-width: var(--max_width);
    height: fit-content;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    //border: 1px solid green;
}

@media only screen and (max-width: 750px) {
    .root_inner_div {
        flex-wrap: wrap;
        gap: 10px;
        padding-bottom: 35px;
    }
}

.root_background{
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: .1;
    //  border: 1px solid green;
}

.left_part_super_div{
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    //border: 1px solid red;
}

@media only screen and (max-width: 1100px) {
    .left_part_super_div {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.first_part_div{
    flex: 1;
    height: fit-content;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    //border: 1px solid red;
}

@media only screen and (max-width: 1100px) {
    .first_part_div {        
        flex: 1;
        margin: 0 auto;
    }
}

.logo_and_legal_text_div{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 0 auto;
    //border: 1px solid green;
}

.logo_div{
    height: 60px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.legal_text_div{
    margin-top: 20px;
    text-align: center;
    color: var(--secondary_colour);
    font-family: light;
    font-size: 11px;
}

.health_tech_portugal_link_div{
    flex-basis: 90%;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    opacity: 1.0;
    will-change: opacity;
    transition: all 0.15s ease-in-out;
    //border: 1px solid red;
}

.health_tech_portugal_link_div:hover{
    cursor: pointer;
    opacity: 0.75;
    transition: all 0.15s ease-in-out;
}

.health_tech_portugal_logo_div{
    aspect-ratio: 1.2;
    //margin-left: 20px;
    width: 130px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    //border: 1px solid red;
}

.health_tech_portugal_separator_div{
    height: 50%;
    width: 2px;
    opacity: 0.35;
    background-color: var(--secondary_colour);
}

.health_tech_portugal_text_div{
    text-align: left;
    width: fit-content;
    color: var(--secondary_colour);
    font-family: light;
    font-size: 11px;
    word-wrap: break-word; 
    //margin-right: 20px;
    //border: 1px solid red;
}

.health_tech_portugal_text_div bold{
    text-align: left;
    color: var(--secondary_colour);
    font-family: regular;
    font-size: 11px;
}

.second_part_div{
    height: fit-content;
    width: fit-content;
    flex: 1;
    min-width: 180px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    //border: 1px solid red;
}

@media only screen and (max-width: 750px) {
    .second_part_div {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 617px) {
    .second_part_div {
        padding-bottom: 10px;
    }
}

.second_part_item_div{
    flex-basis: 100%;
    height: fit-content;
}

.second_part_item_title_div{
    letter-spacing: 2px;
    text-align: left;
    color: var(--primary_colour);
    font-family: semibold;
    font-size: 15px;
}

@media only screen and (max-width: 1020px) {
    .second_part_item_title_div {
        text-align: center;
    }
}

.second_part_item_description_div{
    margin-top: 5px;
    text-align: left;
    color: var(--secondary_colour);
    font-family: light;
    font-size: 13px;
}

.second_part_item_description_div bold{
    color: var(--secondary_colour);
    font-family: semibold;
}

@media only screen and (max-width: 1020px) {
    .second_part_item_description_div {
        text-align: center;
    }
}

.second_part_item_description_div grey{
    position: relative;
    font-family: light;
    font-style: italic;
    font-size: 12px;
    color: rgba(50, 50, 50, 0.45);
    opacity: 1.0;
}

.socials_div{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-content: center;
    justify-content: left;
    //margin: 0 auto;
    flex-wrap: nowrap;
    gap: 20px;
    //border: 1px solid red;
}

@media only screen and (max-width: 1020px) {
    .socials_div {
        width: 100%;
        justify-content: center;
    }
}

.social_item_div{
    height: fit-content;
    width: fit-content;
    padding: 12px;
    border-radius: 200px;
    background-color: rgba(241, 241, 241, 1.0);
    transform: scale(0.9);
    will-change: transform;
    transition: transform 0.15s ease-in-out;
}

.social_item_div:hover{
    cursor: pointer;
    will-change: transform;
    transform: scale(1.0);
    transition: transform 0.15s ease-in-out;
}

.social_item_icon{
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    //border: 1px solid green;
}

.third_part_div{
    height: fit-content;
    position: relative;
    overflow: visible;
    //border: 2px solid magenta;
}

@media only screen and (max-width: 1000px) {
    .third_part_div {
        width: 100%;
    }
}

.contact_us_inner_div{
    height: fit-content;
    width: 100%;
    //border-bottom: 1px solid rgba(215, 215, 215, 1.0);
    background-color: rgba(241, 241, 241, 1.0);
    border-left: 1px solid rgba(57, 74, 106, 0.45);
    border-right: 1px solid rgba(57, 74, 106, 0.45);
}

@media only screen and (max-width: 1020px) {
    .contact_us_inner_div {
        margin: 0 auto;
        background-color: rgba(241, 241, 241, 0.0);
        border-left: 1px solid rgba(57, 74, 106, 0.45);
        border-right: 1px solid rgba(57, 74, 106, 0.45);
    }
}

@media only screen and (max-width: 750px) {
    .contact_us_inner_div {
        width: 100%;
        border: 1px solid rgba(57, 74, 106, 0.0);
    }
}

.contact_us_suptitle{
    font-family: light;
    font-size: 17px;
    margin: 0 auto;
    text-align: center;
    margin-top: 15px;
    color: rgba(100, 100, 100, 1.0);
    //color: var(--secondary_colour);
}

.contact_us_title{
    font-family: bold;
    font-size: 20px;
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;
    color: var(--primary_colour);
    //color: var(--secondary_colour);
}

.contact_us_image_div{
    //flex: 2;
    flex: 0;
    //flex-basis: 55%;
    flex-basis: 0%;
    //border: 1px solid green;
}

.contact_us_image_fade_right{
    display: none;
    height: 100%;
    width: 15%;
    bottom: 0;
    right: calc(50vw + 5%);
    position: absolute;
    z-index: 10000;
    background-color: rgb(201, 207, 225);
    mask-image: linear-gradient(to left, rgb(0, 16, 141) 50%, rgba(249,249,249,0));
    //border: 10px solid green;
}

.contact_us_image_fade_bottom{
    border: 5px solid green;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10000;
    opacity: 1.0;
    //background-color: red;
    mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(0, 0, 0, 0.0));
}

.contact_us_form_div{
    position: relative;
    margin: 0 auto;
    height: fit-content;
    width: fit-content;
    padding-top: 25px;
    padding-bottom: 25px;
    border-left: 1px solid rgba(200, 200, 200, 0.2);
    border-right: 1px solid rgba(200, 200, 200, 0.2);
    background-color: rgba(66, 72, 86, 0.95);
    //border: 1px solid blue;
}

@media only screen and (max-width: 750px) {
    .contact_us_form_div {
        width: 100%;
        border-radius: 12px;
        border: 1px solid rgba(200, 200, 200, 0.2);
        //border: 1px solid red;
    }
}

@supports (backdrop-filter: blur(15px)) {
    .contact_us_form_div{
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
}

.text_field_div{
    height: 100%;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;
    //border: 1px solid red;
}

@media only screen and (max-width: 600px) {
    .text_field_div {
        width: 85%;
    }
}

.text_field{
    position: relative;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    font-family: light;
    font-size: 10px;
}

.contact_us_button{

    box-sizing: border-box;
    border-radius: 20px;
    //background: var(--dark_blue);
    background: var(--primary_colour);
    //border: 2px solid var(--primary_colour);
    //border: 2px solid white;
    margin: 0 auto;
    height: 40px;
    width: fit-content;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 17px;
    padding-bottom: 17px;
    position: relative;
    white-space: nowrap;
    justify-content: center; // horizontal align
    display: flex; // vertical align
    align-items: center; // vertical align
    transition: all ease 0.25s;
}

.contact_us_button:hover{
    cursor: pointer;
    box-shadow: rgba(51, 89, 180, 0.75) 0px 2px 10px 0px;
    transition: all ease 0.25s;
}

.contact_us_button[show_bounce_animation='1'] {
    animation: bounce 0.25s 1;
}

.contact_us_button_text{
    color: white;
    font-family: regular;
    font-size: 13.5px;
    transition: all 0.15s ease-in-out;
}

.vertical_align{
    vertical-align: middle;
    display: table-cell;
    height: 100%;
    z-index: 1000;
    width: 100%;
}

.contact_us_button_icon_div{
    position: absolute;
    z-index: 2000;
    height: fit-content;
    top: 4px;
    bottom: 0;
    margin: auto 0;
    text-align: center;
    left: 0;
    right: 0;
    transition: opacity 0.15s ease-in-out;
    //border:  1px solid red;
}

.status_message_div{
    color: var(--secondary_colour);
    font-family: regular;
    font-size: 13.5px;
    width: 100%;
    margin-top: 15px;
    text-align: center;
    opacity: 0.0;
    transition: all 0.25s ease-in-out;
}

.status_icon_div{
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin: 0 auto;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}