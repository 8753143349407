.root_div{
    position: relative;
    height: fit-content;
    width: 100vw;
    padding-top: 65px;
    padding-bottom: 75px;
    //border: 2px solid green;
}

.root_inner_div{
    position: relative;
    height: fit-content;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: var(--max_width);
    //border: 2px solid green;
}

.root_div_background{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.04;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //border: 2px solid green;
}

.main_title_super_div{
    position: relative;
    padding: 20px;
    border-top: 0.01in solid rgba(208, 227, 254, 0.9);
    border-bottom: 0.01in solid rgba(208, 227, 254, 0.9);
    background-color: rgba(208, 227, 254, 0.1);
}

.main_title{
    width: 100%;
    font-family: regular;
    font-style: italic;
    font-size: 25px;
    margin: 0 auto !important;
    position: relative;
    text-align: center;
    color: var(--primary_colour);
    //border: 1px solid red;
}

.main_title blue{
    background: linear-gradient(330deg, #498CA2 0%, #899EC7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: semibold;
}

.main_title_background{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-repeat: repeat;
    background-size: 100px;
    background-position: center;
    //border: 2px solid green;
}

.video_div{
    margin-top: 70px;
    margin-bottom: 70px;
    width: 100%;
    max-width: var(--max_width);
}

.services_div{
    display: flex;
    flex-wrap: no-wrap;
    gap: 35px;
    justify-content: center;
    flex-direction: row;
    //border: 1px solid red;
}

@media only screen and (max-width: 1000px) {
    .services_div {
        flex-wrap: wrap;
    }
}

.service_super_div{
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    width: 33%;
    min-width: 280px;
    padding: 20px;
    border-radius: 12px;
    background-color: rgba(248, 252, 253, 0.75);
    border: 1px solid rgba(240, 240, 240, 1.0);
    //border: 1px solid red;
}

@media only screen and (max-width: 750px) {
    .service_super_div {
        width: 75%;
    }
}

.service_text_div{
    flex: 1;
    flex-basis: 45%;
    height: fit-content;
    //border: 1px solid red;
}

.service_text_inner_div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    //border: 1px solid red;
}

.suptitle{
    flex-basis: 100%;
    font-family: light;
    font-size: 13px;
    margin: 0 auto;
    text-align: left;
    color: rgba(100, 100, 100, 1.0);
    //border: 1px solid red;
}

@media only screen and (max-width: 1000px) {
    .suptitle{
        text-align: center;
    }
}

.title{
    flex-basis: 100%;
    font-family: bold;
    font-size: 20px;
    margin: 0 auto;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--primary_colour);
}

@media only screen and (max-width: 1000px) {
    .title{
        text-align: center;
    }
}

.description_items{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.description_item{
    flex-basis: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
}

.description_item_bullet_symbol{
    opacity: 0.2;
}

.description_item_text{
    flex-basis: 100%;
    font-family: light;
    font-size: 17px;
    margin: 0 auto;
    text-align: left;
    color: rgba(100, 100, 100, 1.0);
    //border: 1px solid red;
}