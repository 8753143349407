@keyframes bounce {
    0% { transform: scale(1, 1) }
    50% { transform: scale(0.95, 0.95) }
    100% { transform: scale(1, 1) translateX(0px) }
}

.button_container{
    height: var(--up_arrow_height);
    width: var(--up_arrow_width);
    position: fixed;
    right: 20px;
    bottom: 20px;
    transition: right 0.5s;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1.0);
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid var(--secondary_colour);
    will-change: opacity;
    transition: all 0.25s ease-in-out;
}

@supports (backdrop-filter: blur(20px)) {
    .button_container{
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
    }
}

.button_container:hover{
    box-shadow: rgba(51, 89, 180, 0.55) 0px 2px 10px 0px;
}

.button_container[show_bounce_animation='1'] {
    animation: bounce 0.25s 1;
}

.button_transition {
    right: 15px;
}

.arrow_div{
    height: fit-content;
    width: fit-content;
    margin: 0 auto;
    padding-top: 3px;
    //border: 1px solid red;
}