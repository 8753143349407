.root{
    position: relative;
    height: 100vh;
    width: 100%;
    margin-top: var(--navbar_height);
    //border: 2px solid green;
}

.root_background_div{
    height: 100%;
    width: 100vw;
    opacity: 1.0;
    position: absolute;
    z-index: -1000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.root_inner_div{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 auto;
    max-width: var(--max_width);
}

.first_gradient_div{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #B2C2E0 0%, rgba(217, 217, 217, 0.00) 100%);
}

.second_gradient_div{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #B2C2E0 0%, rgba(217, 217, 217, 0.00) 100%);
}

.title_div{
    height: fit-content;
    width: fit-content;
    position: relative; 
    //border: 1px solid red;
}

.title_background_div{
    height: 100%;
    width: 100%;
    opacity: 0.3;
    position: absolute;
    background-repeat: repeat;
    background-size: 300px;
    background-position: center;
}

.title_inner_div{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 40px;
    height: fit-content;
    width: fit-content;
    position: relative;
}

.title_main_text_div{
    font-family: strong;
    flex-basis: 100%;
    color: var(--primary_colour);
}

.title_secondary_text_div{
    font-family: regular;
    flex-basis: 100%;
    color: var(--tertiary_colour);
}